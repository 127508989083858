import React, { useEffect, useState } from "react";
import "../Styles/Users.css";
import Background from "../Assets/bg_img.jpg";
import { get, onValue, ref, remove } from "firebase/database";
import { auth, db } from "./firebase";
import { CancelRounded } from "@material-ui/icons";
import sad_face from "../Assets/sad_face.png";
import { PersonSharp } from "@material-ui/icons";
import "../Styles/modal.css";
import "../Styles/ToggleSwitch.css";
import { getAuth } from "firebase/auth";
import emailjs from "@emailjs/browser";

const ShowImage = ({ image }) => {
  const [showIMage, setImage] = useState("");

  const itemsRef1 = ref(db, "Admin/Users/" + image);

  function dataTask() {
    let isMounted = true;
    onValue(itemsRef1, (snapshot) => {
      if (isMounted) {
        const dataVal = snapshot.val();

        setImage(dataVal.profile);
      }
    });
    return () => {
      isMounted = false;
    };
  }

  useEffect(() => {
    dataTask();
  }, []);

  if (showIMage) {
    return (
      <div>
        <img
          src={showIMage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "https://bit.ly/42v16s3";
          }}
          style={{ width: 50, height: 50, borderRadius: 30 }}
        />
      </div>
    );
  } else {
    return (
      <div>
        <img
          src={"https://bit.ly/42v16s3"}
          style={{
            alignSelf: "center",
            justifyContent: "center",
            width: 50,
            height: 50,
            borderRadius: 30,
          }}
        />
      </div>
    );
  }
};

const Users = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        CheckAccount(user.uid);
        readData();
        //console.log(userId);
      }
    });
    return () => unsubscribe();
  }, []);

  const userId = user ? user.uid : null;

  const [data, setData] = useState("");

  const CheckAccount = (user) => {
    const itemsRef1 = ref(db, "Admin/Users/" + user);
    get(itemsRef1).then((snapshot) => {
      const dataVal = snapshot.val();

      if (dataVal.AccountType == "Administrator") {
        setData("Yes");
      } else if (dataVal.AccountType == "Administrator") {
        setData("No");
      }
    });
  };

  const [showData, getData] = useState([]);
  const [total, setTotal] = useState();
  const [aboutVisible, setAboutVisible] = useState(false);
  const [masterDataSource, setMasterDataSource] = useState([]);
  const [search, setSearch] = useState("");

  const itemData = ref(db, "Admin/Users/");
  function readData() {
    onValue(itemData, (snapshot) => {
      let showData = [];
      let total = 0;
      snapshot.forEach((child) => {
        total = child.size;
        showData.push({
          id: child.key,
          key: child.val(),
        });
      });
      setTotal(total);
      getData(showData);
      setMasterDataSource(showData);
    });
  }

  function handleSubmit(search) {
    if (search) {
      const newData = masterDataSource.filter(function (item) {
        const itemData = item.key.Name ? item.key.Name : "";
        return itemData.indexOf(search) > -1;
      });

      if (newData.length == 0) {
        const newData2 = masterDataSource.filter(function (item) {
          const itemData = item.key.Email ? item.key.Email : "";
          return itemData.indexOf(search) > -1;
        });
        getData(newData2);
      } else {
        getData(newData);
      }
    } else {
      getData(masterDataSource);
    }
  }

  const ShowAccounts = () => {
    if (showData.length == 0) {
      return (
        <div>
          <div className="show-No-Data">
            <img src={sad_face} style={{ width: 120, height: 120 }} />
            <span
              style={{
                fontSize: 20,
                textAlign: "center",
              }}
            >
              No Data Available
            </span>
          </div>
        </div>
      );
    } else {
      return showData.map((item, index) => (
        <div key={index}>
          <div
            style={{
              borderRadius: 10,
              backgroundColor: "white",
              padding: 10,
              margin: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ShowImage image={item.id} />
              <div
                style={{
                  marginLeft: 15,
                  alignSelf: "center",
                  alignItems: "start",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: 16, fontWeight: "500" }}>
                  Name: {item.key.Name}
                </span>
                <span style={{ fontSize: 16, fontWeight: "500" }}>
                  Email: {item.key.Email}
                </span>
              </div>
            </div>
            {item.key.AccountType == "Administrator" ? (
              <div
                style={{
                  marginLeft: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    color: "white",
                    borderRadius: 10,
                    padding: 10,
                    backgroundColor: "teal",
                    width: 120,
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  {item.key.AccountType}
                </span>
              </div>
            ) : (
              <div
                style={{
                  marginLeft: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    color: "white",
                    borderRadius: 10,
                    padding: 10,
                    backgroundColor: "teal",
                    width: 120,
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  {item.key.AccountType}
                </span>
                <div
                  onClick={() => {
                    setDialogOpen(true);
                    setActiveUser(item);
                  }}
                  style={{
                    color: "red",
                    borderRadius: 10,
                    marginTop: 10,
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <span className="details-move">Delete</span>
                </div>
              </div>
            )}
          </div>
          {dialogOpen ? <Modal setOpenModal={setDialogOpen} /> : null}
        </div>
      ));
    }
  };

  //Delete Modal
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);
  const [activeUser, setActiveUser] = useState(null);

  const Modal = ({ setOpenModal }) => {
    return (
      <div className="modalBackground">
        <div className="modalContainer">
          <div className="titleCloseBtn">
            <button
              onClick={() => {
                setOpenModal(false);
              }}
            >
              X
            </button>
          </div>
          <div className="title">
            <h1>Are You Sure You Want to Delete? {activeUser.key.Name} </h1>
          </div>

          <div className="footer-button">
            <button
              onClick={() => {
                setOpenModal(false);
              }}
              id="cancelBtn"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setOpenModal(false);
                removeFromArray(activeUser);
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  };

  function removeFromArray(activeUser) {
    const itemsRef = ref(db, "Admin/Users/" + activeUser.id);
    remove(itemsRef);
  }

  //Invite Modal

  const [viewer, setViewer] = useState(null);

  const [getEmail, setEmail] = useState("");

  //Send Invite
  function SendInvite() {
    if (getEmail && viewer !== null) {
      const emailContent = {
        from_name: "Support",
        to_email: getEmail,
        message:
          "You have been invited to join the bookhub administrative account as " +
          viewer +
          ". Please use the link below to proceed",
        link: "https://bookhubadmin.web.app/login",
      };

      emailjs
        .send(
          "service_i17f0qg",
          "template_ss43lzp",
          emailContent,
          "mREvRoSveJqHR93ne"
        )
        .then(
          (result) => {
            console.log("complete => " + result.text);
            setEmail("");
            setViewer("");
            setDialogOpen2(false);
            alert("invite sent to " + getEmail);
          },
          (error) => {
            alert("Error=> " + error.text);
          }
        );
      // /console.log(getEmail + " " + viewer);
    } else {
      alert("Please Enter Email & Choose a Privilege");
    }
  }

  if (data == "Yes") {
    return (
      <div
        className="container"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: 30,
        }}
      >
        <div className="body-section">
          <div className="sect">
            <div>
              <form className="search-section">
                <input
                  placeholder="Search"
                  onChange={(e) => {
                    handleSubmit(e.target.value);
                  }}
                  type="search"
                  defaultValue={search}
                  className="search-field"
                />
              </form>
            </div>
            <div className="header-section">
              <div
                onClick={() => {
                  setDialogOpen2(true);
                }}
                className="link-data"
              >
                Invite
              </div>
            </div>
          </div>

          <div className="results-section">
            <ShowAccounts />
            {dialogOpen2 ? (
              <div className="modalBackground">
                <div className="modalContainer">
                  <div
                    className="titleCloseBtn"
                    style={{
                      backgroundColor: "black",
                      borderRadius: 20,
                      color: "white",
                    }}
                  >
                    <h2>Invite a user </h2>
                    <button
                      onClick={() => {
                        setDialogOpen2(false);
                      }}
                      style={{
                        color: "red",
                        alignSelf: "center",
                        marginLeft: 20,
                      }}
                    >
                      X
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      marginTop: 10,
                    }}
                  >
                    <input
                      className="text-input"
                      style={{
                        fontSize: 20,
                        color: "black",
                        fontWeight: "700",
                      }}
                      onChange={(text) => setEmail(text.target.value)}
                      value={getEmail}
                      placeholder="Enter User's Email"
                    />
                    <h3>Choose Privilege Type</h3>
                    <div></div>
                    <div
                      style={{
                        padding: 10,
                        backgroundColor: "#d8e0ed",
                        borderRadius: 10,
                        width: "100%",
                        alignItems: "start",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ alignSelf: "center", marginRight: 20 }}>
                          Administrator
                        </span>
                        <div className="toggle-switch">
                          <input
                            type="checkbox"
                            className="checkbox"
                            onChange={(e) => setViewer("Administrator")}
                            checked={viewer === "Administrator"}
                            name={"Administrator"}
                            id={"Administrator"}
                          />
                          <label className="label" htmlFor={"Administrator"}>
                            <span className="inner" />
                            <span className="switch" />
                          </label>
                        </div>
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <span>View , Add , update , Delete</span>
                      </div>
                    </div>

                    <div
                      style={{
                        padding: 10,
                        backgroundColor: "#d8e0ed",
                        borderRadius: 10,
                        width: "100%",
                        alignItems: "start",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ alignSelf: "center", marginRight: 20 }}>
                          Editor
                        </span>
                        <div className="toggle-switch">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={"Editor"}
                            onChange={(e) => setViewer("Editor")}
                            checked={viewer === "Editor"}
                            id={"Editor"}
                          />
                          <label className="label" htmlFor={"Editor"}>
                            <span className="inner" />
                            <span className="switch" />
                          </label>
                        </div>
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <span>View , Add , update </span>
                      </div>
                    </div>

                    <div
                      style={{
                        padding: 10,
                        backgroundColor: "#d8e0ed",
                        borderRadius: 10,
                        width: "100%",
                        alignItems: "start",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ alignSelf: "center", marginRight: 20 }}>
                          Accountant
                        </span>
                        <div className="toggle-switch">
                          <input
                            type="checkbox"
                            className="checkbox"
                            onChange={(e) => setViewer("Accountant")}
                            checked={viewer === "Accountant"}
                            name={"Accountant"}
                            id={"Accountant"}
                          />
                          <label className="label" htmlFor={"Accountant"}>
                            <span className="inner" />
                            <span className="switch" />
                          </label>
                        </div>
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <span>View , Add </span>
                      </div>
                    </div>

                    <div
                      style={{
                        padding: 10,
                        backgroundColor: "#d8e0ed",
                        borderRadius: 10,
                        width: "100%",
                        alignItems: "start",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ alignSelf: "center", marginRight: 20 }}>
                          Viewer
                        </span>
                        <div className="toggle-switch">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={"Viewer"}
                            onChange={(e) => setViewer("Viewer")}
                            checked={viewer === "Viewer"}
                            id={"Viewer"}
                          />
                          <label className="label" htmlFor={"Viewer"}>
                            <span className="inner" />
                            <span className="switch" />
                          </label>
                        </div>
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <span>View</span>
                      </div>
                    </div>

                    <div
                      className="footer-button"
                      style={{ alignSelf: "center" }}
                    >
                      <button
                        onClick={() => {
                          setDialogOpen2(false);
                        }}
                        id="cancelBtn"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          SendInvite();
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="container"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: 30,
        }}
      >
        <div className="body-section-users">
          <div className="show-No-Data">
            <CancelRounded
              style={{
                fontSize: 80,
                alignSelf: "center",
                justifyContent: "center",
                color: "red",
              }}
            />
            <span
              style={{
                fontSize: 20,
                textAlign: "center",
              }}
            >
              No Sufficient Privileges to Access
            </span>
          </div>
        </div>
      </div>
    );
  }
};

export default Users;
