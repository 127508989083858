import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase, ref } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCrOsWcXCMbgG4fGJotd1UgrJtsDD2182k",
  authDomain: "thebookhub260.firebaseapp.com",
  databaseURL: "https://thebookhub260-default-rtdb.firebaseio.com",
  projectId: "thebookhub260",
  storageBucket: "thebookhub260.appspot.com",
  messagingSenderId: "1017237533677",
  appId: "1:1017237533677:web:290f49096c1c9cf824ade4",
  measurementId: "G-QH861PPT67",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

export { auth };
export { app };
export { db };
export { ref };
