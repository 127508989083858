import React, { useEffect, useState } from "react";
import "../Styles/notifications.css";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db, ref } from "./firebase";
import moment from "moment";
import { ArrowBackIos, Delete } from "@material-ui/icons";
import { onValue } from "firebase/database";

const Notifications = ({ data }) => {
  const [notify, setNotify] = useState([]);
  const [notifyNumber, setNotifyNumber] = useState(0);
  const [mainData, setMainData] = useState();
  const [data2, setData2] = useState();
  const [showNotif, setShowNot] = useState(data ? false : true);

  let userData = window.localStorage.getItem("userData");

  const [userInfo, setUserInfo] = useState("");

  useEffect(() => {
    let getUser = userData ? JSON.parse(userData) : [];

    setUserInfo(getUser);
  }, []);

  useEffect(() => {
    if (data) {
      setMainData(data);
    }
  }, [data]);

  const fetchNotifications = async () => {
    const driversRef = ref(db, "Admin/Notifications");

    const notify = [];

    onValue(driversRef, (snapshot) => {
      if (snapshot.exists()) {
        setNotifyNumber(snapshot.size);

        snapshot.forEach((doc) => {
          const data = doc.val();

          notify.push({
            id: doc.id,
            ...data,
          });
        });

        const newData = notify.sort((a, b) => (a.read > b.read ? 1 : -1));

        setNotify(newData);
      }
    });
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const Card = ({ data }) => {
    const formatDate = (timestamp) => {
      const date = timestamp.toDate();

      const formattedDate = moment(
        date.toLocaleDateString(),
        "MM/DD/YYYY"
      ).format("DD/MM/YYYY");

      return formattedDate;
    };

    const renderDots = (read) => {
      let dotColor = read === true ? "grey" : "orange";
      return <div className="dot" style={{ backgroundColor: dotColor }}></div>;
    };

    return (
      <div className="notifyCard" onClick={() => handleUpdate(data)}>
        <div className="date">{formatDate(data.timestamp)}</div>
        <div className="line">{renderDots(data.read)}</div>
        <div className="content">
          <h3>{data.title}</h3>
          <p>{data.message}</p>
        </div>
      </div>
    );
  };

  async function handleUpdate(items) {
    if (items.read === false) {
      const collectionRef = collection(db, "notifications");

      const docRef = doc(collectionRef, items.id);

      const updateData = {
        read: true,
      };

      // Update the document with the new data
      await updateDoc(docRef, updateData)
        .then(async () => {
          setNotify([]);
          setShowNot(false);
          setMainData();
          setData2(items);
          fetchNotifications();
        })
        .catch((error) => console.log("error=> " + error));
    } else {
      setShowNot(false);
      setMainData();
      setData2(items);
    }
  }

  const ShowMessage = ({ data }) => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 10,
            backgroundColor: "#7c81ad",
            color: "white",
            padding: 10,
            borderRadius: 5,
          }}
        >
          <span>{data.title}</span>
          {userInfo === "administrator" && (
            <div style={{ cursor: "pointer" }}>
              <Delete />
            </div>
          )}
        </div>

        <div
          style={{
            marginTop: 10,
            padding: 10,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ fontWeight: "700" }}>Message</div>
          <div style={{ fontStyle: "italic", marginLeft: 20 }}>
            {data.message}
          </div>

          <div style={{ alignSelf: "flex-end", marginTop: 20 }}>
            {moment.unix(data.timestamp.seconds).format("MM/D/YYYY")}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="notify_tab">
      <div className="left">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 10,
            backgroundColor: "#7c81ad",
            color: "white",
            padding: 10,
            borderRadius: 5,
          }}
        >
          <div>Notifications</div>
          {userInfo === "administrator" && (
            <div style={{ cursor: "pointer" }}>
              <Delete />
            </div>
          )}
        </div>

        <hr style={{ width: "80%" }} />

        {notify.length > 0
          ? notify.map((items, index) => <Card key={index} data={items} />)
          : null}
      </div>
      <div className="right">
        {(showNotif === false && mainData) || (showNotif === false && data2) ? (
          <ShowMessage data={mainData ? mainData : data2} />
        ) : (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div> No Notification selected</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
