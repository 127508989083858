import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Styles/LeftNav.css";
import {
  Dashboard,
  AccountBalanceWalletSharp,
  PeopleSharp,
  VerifiedUser,
  BusinessSharp,
  ArrowRightRounded,
  Settings,
} from "@material-ui/icons";

import { useLocation } from "react-router-dom";
import { red } from "@material-ui/core/colors";
import { auth } from "./firebase";

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

function LeftNav() {
  const navigation = useNavigate();
  const [showSub, setShowSub] = useState(false);
  const path = usePathname();
  //console.log(path);
  return (
    <div className="left_nav">
      <div className="links">
        {path === "/Home" ? (
          <button
            onClick={() => {
              navigation("/Home");
              setShowSub(false);
            }}
            style={{ backgroundColor: "#cfcfcf", color: "black" }}
          >
            <Dashboard
              style={{
                alignSelf: "center",
                fontSize: 15,
                justifyContent: "center",
                color: red,
                marginRight: 10,
              }}
            />
            Dashboard
          </button>
        ) : (
          <button
            onClick={() => {
              navigation("/Home");
              setShowSub(false);
            }}
          >
            <Dashboard
              style={{
                alignSelf: "center",
                fontSize: 15,
                justifyContent: "center",
                marginRight: 10,
              }}
            />
            Dashboard
          </button>
        )}
        {path === "/Accounts" ? (
          <button
            onClick={() => {
              navigation("/Accounts");
              setShowSub(false);
            }}
            style={{ backgroundColor: "#cfcfcf", color: "black" }}
          >
            <VerifiedUser
              style={{
                alignSelf: "center",
                fontSize: 15,
                justifyContent: "center",
                marginRight: 10,
              }}
            />
            Accounts
          </button>
        ) : (
          <button
            onClick={() => {
              navigation("/Accounts");
              setShowSub(false);
            }}
          >
            <VerifiedUser
              style={{
                alignSelf: "center",
                fontSize: 15,
                justifyContent: "center",
                marginRight: 10,
              }}
            />
            Accounts
          </button>
        )}
        {path === "/Businesses" ? (
          <button
            onClick={() => {
              navigation("/Businesses");
              setShowSub(false);
            }}
            style={{ backgroundColor: "#cfcfcf", color: "black" }}
          >
            <BusinessSharp
              style={{
                alignSelf: "center",
                fontSize: 15,
                justifyContent: "center",
                marginRight: 10,
              }}
            />
            Business
          </button>
        ) : (
          <button
            onClick={() => {
              navigation("/Businesses");
              setShowSub(false);
            }}
          >
            <BusinessSharp
              style={{
                alignSelf: "center",
                fontSize: 15,
                justifyContent: "center",
                marginRight: 10,
              }}
            />
            Business
          </button>
        )}
        {path === "/Payments" ? (
          <div>
            <button
              onClick={() => {
                showSub ? setShowSub(false) : setShowSub(true);
                navigation("/Payments");
              }}
              style={{ backgroundColor: "#cfcfcf", color: "black" }}
            >
              <AccountBalanceWalletSharp
                style={{
                  alignSelf: "center",
                  fontSize: 15,
                  justifyContent: "center",
                  marginRight: 10,
                }}
              />
              Payments
            </button>
            {showSub ? (
              <div>
                <button
                  onClick={() => navigation("/Bookings")}
                  style={{
                    backgroundColor: "#cfcfcf",
                    color: "black",
                    marginLeft: 10,
                  }}
                >
                  <ArrowRightRounded
                    style={{
                      alignSelf: "center",
                      fontSize: 15,
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                  />
                  Bookings
                </button>
                <button
                  onClick={() => navigation("Transactions")}
                  style={{
                    backgroundColor: "#cfcfcf",
                    color: "black",
                    marginLeft: 10,
                  }}
                >
                  <ArrowRightRounded
                    style={{
                      alignSelf: "center",
                      fontSize: 15,
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                  />
                  Transactions
                </button>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <button
              onClick={() => {
                showSub ? setShowSub(false) : setShowSub(true);
                navigation("/Payments");
              }}
            >
              <AccountBalanceWalletSharp
                style={{
                  alignSelf: "center",
                  fontSize: 15,
                  justifyContent: "center",
                  marginRight: 10,
                }}
              />
              Payments
            </button>
            {showSub ? (
              <div>
                <button
                  onClick={() => navigation("/Bookings")}
                  style={{
                    backgroundColor: "#cfcfcf",
                    color: "black",
                    marginLeft: 10,
                  }}
                >
                  <ArrowRightRounded
                    style={{
                      alignSelf: "center",
                      fontSize: 15,
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                  />
                  Bookings
                </button>
                <button
                  onClick={() => navigation("Transactions")}
                  style={{
                    backgroundColor: "#cfcfcf",
                    color: "black",
                    marginLeft: 10,
                  }}
                >
                  <ArrowRightRounded
                    style={{
                      alignSelf: "center",
                      fontSize: 15,
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                  />
                  Transactions
                </button>
              </div>
            ) : null}
          </div>
        )}
        {path === "/settings" ? (
          <button
            onClick={() => {
              navigation("/settings");
              setShowSub(false);
            }}
            style={{ backgroundColor: "#cfcfcf", color: "black" }}
          >
            <Settings
              style={{
                alignSelf: "center",
                fontSize: 15,
                justifyContent: "center",
                marginRight: 10,
              }}
            />
            Settings
          </button>
        ) : (
          <button
            onClick={() => {
              navigation("/settings");
              setShowSub(false);
            }}
          >
            <Settings
              style={{
                alignSelf: "center",
                fontSize: 15,
                justifyContent: "center",
                marginRight: 10,
              }}
            />
            Settings
          </button>
        )}
      </div>
    </div>
  );
}
export default LeftNav;
