import React, { useEffect, useState } from "react";
import "../Styles/Bookings.css";
import Background from "../Assets/bg_img.jpg";
import sad_face from "../Assets/sad_face.png";
import { get, onValue, ref } from "firebase/database";
import { auth, db } from "../Component/firebase";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import PhoneDesign from "../Assets/phone_design.png";
import { ArrowBack, PersonSharp } from "@material-ui/icons";
import emailjs from "@emailjs/browser";
import { getAuth } from "firebase/auth";

const ShowImage = ({ image }) => {
  const [showIMage, setImage] = useState("");

  const itemsRef1 = ref(db, "Listing/" + image);

  function dataTask() {
    let isMounted = true;
    get(itemsRef1).then((snapshot) => {
      if (isMounted) {
        const dataVal = snapshot.val();

        setImage(dataVal.Image);
      }
    });
    return () => {
      isMounted = false;
    };
  }

  useEffect(() => {
    dataTask();
  }, []);

  if (showIMage) {
    return (
      <div>
        <img
          src={showIMage}
          alt="User Image"
          style={{ width: 50, height: 50, borderRadius: 30 }}
        />
      </div>
    );
  } else {
    return (
      <div>
        <PersonSharp
          style={{
            alignSelf: "center",
            fontSize: 50,
            justifyContent: "center",
            marginRight: 10,
          }}
        />
      </div>
    );
  }
};

//getViews
const GetViews = ({ item }) => {
  // console.log(item);
  const [showNumber, setNumber] = useState("");

  const itemsRef1 = ref(db, "Listing/" + item + "/Views");

  function dataTask() {
    let isMounted = true;
    get(itemsRef1).then((snapshot) => {
      let showNumber = 0;
      if (isMounted) {
        showNumber = snapshot.size;

        setNumber(showNumber);
      }
    });
    return () => {
      isMounted = false;
    };
  }

  useEffect(() => {
    dataTask();
  }, []);

  return (
    <span
      style={{
        fontWeight: "bold",
        borderRadius: 10,
        padding: 10,
        backgroundColor: "#85ffef",
        width: 120,
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        alignSelf: "center",
      }}
    >
      {showNumber} View{"(s)"}
    </span>
  );
};

const Businesses = () => {
  const [search, setSearch] = useState("");

  //top data
  const [showData, getData] = useState([]);
  const [total, setTotal] = useState();
  const [aboutVisible, setAboutVisible] = useState(false);
  const [masterDataSource, setMasterDataSource] = useState([]);

  const toggleBottomNavigationdiv = () => {
    setAboutVisible(!aboutVisible);
  };

  const itemData = ref(db, "Listing/");
  function readData() {
    get(itemData).then((snapshot) => {
      let showData = [];
      let total = 0;
      total = snapshot.size;
      snapshot.forEach((child) => {
        showData.push({
          id: child.key,
          key: child.val(),
        });
      });
      setTotal(total);
      getData(showData);
      setMasterDataSource(showData);
    });
  }

  const [data, setData] = useState("");
  const [admin, setAdmin] = useState("");
  const [messageTab, setMessageTab] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [getUser, setUser] = useState("");
  const [getEmail, setEmail] = useState("");
  const [getName, setName] = useState(null);
  const [getUsername, setUsername] = useState(null);
  const [getContact, setContact] = useState(null);

  const CheckAccount = (user) => {
    const itemsRef1 = ref(db, "Admin/Users/" + user);
    get(itemsRef1).then((snapshot) => {
      const dataVal = snapshot.val();
      setData(dataVal.AccountType);
      setAdmin(dataVal.Name);
    });
  };

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        CheckAccount(user.uid);
        readData();
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSearch = (text) => {
    if (text) {
      const newData = masterDataSource.filter(function (item) {
        const itemData = item.key.AccountType ? item.key.AccountType : "";
        return itemData.indexOf(text) > -1;
      });

      getData(newData);
    } else {
      getData(masterDataSource);
    }
  };

  function handleSubmit(search) {
    if (search) {
      const newData = masterDataSource.filter(function (item) {
        const itemData = item.key.Name ? item.key.Name : "";
        return itemData.indexOf(search) > -1;
      });

      if (newData.length == 0) {
        const newData2 = masterDataSource.filter(function (item) {
          const itemData = item.key.Category ? item.key.Category : "";
          return itemData.indexOf(search) > -1;
        });

        getData(newData2);
      } else {
        const newData3 = masterDataSource.filter(function (item) {
          const itemData = item.key.City ? item.key.City : "";
          return itemData.indexOf(search) > -1;
        });

        if (newData3.length > 0) {
          getData(newData3);
        } else {
          getData(newData);
        }
      }
    } else {
      getData(masterDataSource);
    }
  }

  const [showDetails, setShowDetails] = useState(false);

  const ShowBusiness = () => {
    if (showData.length == 0) {
      return (
        <div>
          <div className="show-No-Data">
            <img src={sad_face} style={{ width: 120, height: 120 }} />
            <span
              style={{
                fontSize: 20,
                textAlign: "center",
              }}
            >
              No Data Available
            </span>
          </div>
        </div>
      );
    } else {
      return showData.map((item, index) => (
        <div key={index}>
          <div
            style={{
              borderRadius: 10,
              backgroundColor: "white",
              padding: 10,
              margin: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ShowImage image={item.id} />
              <div
                style={{
                  marginLeft: 5,
                  alignSelf: "center",
                  alignItems: "start",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: 16, fontWeight: "500" }}>
                  Name: {item.key.Name}
                </span>
                <span style={{ fontSize: 16, fontWeight: "500" }}>
                  Category: {item.key.Category}
                </span>
                <span style={{ fontSize: 16, fontWeight: "500" }}>
                  Location: {item.key.City}
                </span>
                {item.key.Active == "Yes" ? (
                  <span
                    style={{ fontSize: 16, fontWeight: "500", color: "green" }}
                  >
                    Status: Active
                  </span>
                ) : (
                  <span
                    style={{ fontSize: 16, fontWeight: "500", color: "red" }}
                  >
                    Status: Inactive
                  </span>
                )}
              </div>
            </div>
            <div
              style={{
                marginLeft: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <GetViews item={item.id} />
              <div
                onClick={() => {
                  setUser(item);
                  setShowDetails(true);
                  console.log(item);
                }}
                style={{
                  color: "blue",
                  borderRadius: 10,
                  marginTop: 10,
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              >
                <span
                  className="details-move"
                  style={{
                    color: "blue",
                    borderRadius: 10,
                  }}
                >
                  Details
                </span>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  };

  const ShowDetails = () => {
    return (
      <div
        style={{
          display: "flex",
          padding: 10,
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            width: "60%",
            padding: 10,
            minHeight: 200,
          }}
        >
          <div
            style={{
              alignItems: "start",
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "white",
              padding: 10,
              borderRadius: 10,
              margin: 5,
            }}
          >
            <span style={{ textAlign: "flex-start" }}>Name</span>
            <span style={{ textAlign: "flex-start" }}>{getUser.key.Name}</span>
          </div>
          <div
            style={{
              alignItems: "start",
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "white",
              padding: 10,
              borderRadius: 10,
              margin: 5,
            }}
          >
            <span style={{ textAlign: "flex-start" }}>City</span>
            <span style={{ textAlign: "flex-start" }}>{getUser.key.City}</span>
          </div>

          <div
            style={{
              alignItems: "start",
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "white",
              padding: 10,
              borderRadius: 10,
              margin: 5,
            }}
          >
            <span style={{ textAlign: "flex-start" }}>Contact</span>
            <span style={{ textAlign: "flex-start" }}>
              {getUser.key.Contact}
            </span>
          </div>

          <div
            style={{
              alignItems: "start",
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "white",
              padding: 10,
              borderRadius: 10,
              margin: 5,
            }}
          >
            <span style={{ textAlign: "flex-start" }}>Description</span>
            <span style={{ textAlign: "flex-start" }}>
              {getUser.key.Description.slice(0, 30)}...
            </span>
          </div>

          <div
            style={{
              alignItems: "start",
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "white",
              padding: 10,
              borderRadius: 10,
              margin: 5,
            }}
          >
            <span style={{ textAlign: "flex-start" }}>Status</span>
            {getUser.key.Active == "Yes" ? (
              <span style={{ fontSize: 16, fontWeight: "500", color: "green" }}>
                Active
              </span>
            ) : (
              <span style={{ fontSize: 16, fontWeight: "500", color: "red" }}>
                Inactive
              </span>
            )}
          </div>
        </div>

        <div
          style={{
            width: "30%",
            minHeight: 260,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              height: 260,
              borderRadius: 20,
              padding: 10,
            }}
          >
            <img
              src={getUser.key.Image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "https://bit.ly/42v16s3";
              }}
              style={{ width: "100%", height: 200, borderRadius: 20 }}
            />
            <button
              type="submit"
              name="btn-submit"
              onClick={() => setMessageTab(true)}
              style={{
                width: 150,
                height: 40,
                backgroundColor: "teal",
                borderColor: "transparent",
                color: "white",
                borderRadius: 10,
                fontSize: 17,
                fontWeight: "bold",
              }}
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
    );
  };

  //Message

  function SendMessage() {
    if (getUser.key.Email && getEmail !== null) {
      const emailContent = {
        from_name: admin,
        to_email: getUser.key.Email,
        message: getEmail,
      };

      emailjs
        .send(
          "service_i17f0qg",
          "template_2ctke1u",
          emailContent,
          "mREvRoSveJqHR93ne"
        )
        .then(
          (result) => {
            console.log("complete => " + result.text);
            setEmail("");
            alert("Message sent to " + getUser.key.Email);
          },
          (error) => {
            alert("Error=> " + error.text);
          }
        );
    } else {
      alert("Please Enter Email & Choose a Privilege");
    }
  }

  return (
    <div
      className="container"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: 30,
      }}
    >
      <div className="body-section">
        <div>
          {showDetails ? (
            <div className="sect">
              <div onClick={() => setShowDetails(false)} className="link-data">
                <ArrowBack
                  style={{
                    width: 25,
                    height: 25,
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="sect">
              <div>
                <form className="search-section">
                  <input
                    placeholder="Search"
                    onChange={(e) => {
                      handleSubmit(e.target.value);
                    }}
                    type="search"
                    defaultValue={search}
                    className="search-field"
                  />
                </form>
              </div>
              <div className="header-section">
                <div
                  style={{
                    marginLeft: 20,
                    alignSelf: "center",
                    fontWeight: "800",
                    fontSize: 18,
                    color: "white",
                  }}
                >
                  {total} Business{"(es)"}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="results-section">
          {showDetails ? <ShowDetails /> : <ShowBusiness />}
          {messageTab ? (
            <div className="modalBackground">
              <div className="modalContainer">
                <div
                  className="titleCloseBtn"
                  style={{
                    backgroundColor: "black",
                    borderRadius: 20,
                    color: "white",
                  }}
                >
                  <h2>Send Message</h2>
                  <button
                    onClick={() => {
                      setMessageTab(false);
                    }}
                    style={{
                      color: "red",
                      alignSelf: "center",
                      marginLeft: 20,
                    }}
                  >
                    X
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: 10,
                  }}
                >
                  <input
                    className="text-input"
                    style={{
                      fontSize: 16,
                      marginBottom: 10,
                    }}
                    value={getUser.key.Email}
                    readOnly={true}
                    placeholder="Enter User's Email"
                  />
                  <textarea
                    className="text-input1"
                    style={{
                      fontSize: 16,
                    }}
                    onChange={(text) => setEmail(text.target.value)}
                    value={getEmail}
                    placeholder="Type Message Here ...."
                  />
                  <div
                    className="footer-button"
                    style={{ alignSelf: "center" }}
                  >
                    <button
                      onClick={() => {
                        SendMessage();
                        setMessageTab(false);
                      }}
                      id="successBtn"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : showEditor ? (
            <div className="modalBackground">
              <div className="modalContainer">
                <div
                  className="titleCloseBtn"
                  style={{
                    backgroundColor: "black",
                    borderRadius: 20,
                    color: "white",
                  }}
                >
                  <h2>Edit Details</h2>
                  <button
                    onClick={() => {
                      setShowEditor(false);
                    }}
                    style={{
                      color: "red",
                      alignSelf: "center",
                      marginLeft: 20,
                    }}
                  >
                    X
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: 10,
                  }}
                >
                  <span style={{ fontSize: 17, fontWeight: "bold" }}>
                    Enter Name
                  </span>
                  <input
                    className="text-input"
                    style={{
                      fontSize: 16,
                    }}
                    onChange={(text) => setName(text.target.value)}
                    value={getName}
                    placeholder={
                      getUser.key.Name ? getUser.key.Name : "Enter Name"
                    }
                  />

                  <span
                    style={{ fontSize: 17, fontWeight: "bold", marginTop: 20 }}
                  >
                    Enter Username
                  </span>

                  <input
                    className="text-input"
                    style={{
                      fontSize: 16,
                    }}
                    onChange={(text) => setUsername(text.target.value)}
                    value={getUsername}
                    placeholder={
                      getUser.key.Username
                        ? getUser.key.Username
                        : "Enter Username"
                    }
                  />

                  <span
                    style={{ fontSize: 17, fontWeight: "bold", marginTop: 20 }}
                  >
                    Enter Contact
                  </span>

                  <input
                    className="text-input"
                    style={{
                      fontSize: 16,
                    }}
                    onChange={(text) => setContact(text.target.value)}
                    value={getContact}
                    placeholder={
                      getUser.key.Contact
                        ? getUser.key.Contact
                        : "Enter Contact"
                    }
                  />

                  <div
                    className="footer-button"
                    style={{ alignSelf: "center" }}
                  >
                    <button
                      onClick={() => {
                        //  / UpdateTask();
                        setShowEditor(false);
                      }}
                      id="successBtn"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default Businesses;
