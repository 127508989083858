import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import SplashScreen from "./Pages/SplashScreen";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Navbar from "./Component/NavBar";
import LeftNav from "./Component/LeftNav";
import Footer from "./Component/Footer";
import Accounts from "./Pages/Accounts";
import Businesses from "./Pages/Businesses";
import Payments from "./Pages/Payments";
import Bookings from "./Pages/Bookings";
import Transactions from "./Pages/Transactions";
import Settings from "./Pages/Settings";

function isMobileDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = ["android", "iphone", "ipad", "ipod", "windows phone"];

  return mobileKeywords.some((keyword) => userAgent.includes(keyword));
}

const Main = () => {
  let location = useLocation();

  if (isMobileDevice()) {
    return (
      <div className="App">
        <main className="main-body">
          <h1 style={{ padding: 10, textAlign: "center" }}>
            Designed for Desktop / Laptop Computers.
          </h1>
        </main>
      </div>
    );
  } else {
    return (
      <div className="App">
        {location.pathname !== "/Login" &&
          location.pathname !== "/SignUp" &&
          location.pathname !== "/" && <Navbar />}
        <div className="mainDiv">
          {location.pathname !== "/Login" &&
            location.pathname !== "/SignUp" &&
            location.pathname !== "/" && <LeftNav className="nav" />}
          <div className="swtches">
            <Routes>
              <Route path="/" exact element={<SplashScreen />} />
              <Route path="/Home" exact element={<Home />} />
              <Route path="/Login" exact element={<Login />} />
              <Route path="/Accounts" exact element={<Accounts />} />
              <Route path="/Businesses" exact element={<Businesses />} />
              <Route path="/Payments" exact element={<Payments />} />
              <Route path="/settings" exact element={<Settings />} />
              <Route path="/Bookings" exact element={<Bookings />} />
              <Route path="/Transactions" exact element={<Transactions />} />
            </Routes>
          </div>
        </div>
        {location.pathname !== "/Login" &&
          location.pathname !== "/SignUp" &&
          location.pathname !== "/" && <Footer />}
      </div>
    );
  }
};

const App = () => {
  return (
    <Router>
      <Main />
    </Router>
  );
};

export default App;
