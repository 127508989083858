import React, { useEffect, useState } from "react";
import "../Styles/Login.css";
import { app, auth, db, ref } from "../Component/firebase";
import booking_woman from "../Assets/booking_woman.jpg";
import Logo from "../Assets/book_logo2.png";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { get, onValue } from "firebase/database";
import Background from "../Assets/bg_img.jpg";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

function Login() {
  const navigation = useNavigate();

  const [type, setType] = useState("password");

  let logInUser = window.localStorage.getItem("isBookHubAdmin");

  useEffect(() => {
    if (logInUser) {
      navigation("/Home");
    } else {
    }
  }, []);

  const handleClick = () => {
    if (type === "text") {
      setType("password");
    } else {
      setType("text");
    }
  };

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        const user = userCredentials.user;
        const itemsRef1 = ref(db, "Admin/Users/" + user.uid);

        get(itemsRef1).then((snapshot) => {
          if (snapshot.exists()) {
            user
              ? window.localStorage.setItem("isBookHubAdmin", user.uid)
              : window.localStorage.removeItem("isBookHubAdmin");
            alert("Login Succesful");

            navigation("/Home");
          } else {
            alert("Not Registered as Administrator");
          }
        });
      })
      .catch((error) => alert("Wrong Email or Password"));
  };

  async function handleGoogleLogin() {
    const auth = getAuth(app);

    const provider = new GoogleAuthProvider();
    provider.addScope("email");
    provider.addScope("profile");

    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      //navigation.navigate("Home", user);
      console.log(user);
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData ? error.customData.email : null;

      console.log(errorCode);
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    }
  }

  return (
    <div
      className="container-login"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "darken",
      }}
    >
      <div className="login-div">
        <div className="right-div">
          <img
            src={Logo}
            alt={"Logo"}
            style={{ height: 80, width: 80, alignSelf: "center" }}
          />
          <h3 style={{ alignSelf: "center" }}>BOOKHUB ADMIN PANEL</h3>
          <form onSubmit={handleSubmit}>
            <input
              placeholder="Enter Email Address"
              name={email}
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className="password-sect">
              <input
                placeholder=" Enter Password"
                name={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type={type}
              />
              <span
                className="password__show"
                onClick={handleClick}
                style={{ marginLeft: 10 }}
              >
                {type === "text" ? "Hide" : "Show"}
              </span>
            </div>

            <button type="submit" name="btn-submit" onClick={handleSubmit}>
              Login
            </button>
          </form>
        </div>
        <div className="left-div">
          <img src={booking_woman} alt={"LOGO"} className="left-image" />
        </div>
      </div>
    </div>
  );
}

export default Login;
