import React, { useEffect, useRef, useState } from "react";
import PhoneDesign from "../Assets/phone_design2.png";
import playStore from "../Assets/playStore.png";
import appleStore from "../Assets/appleStore.png";
import "../Styles/Home.css";
import { Link, useNavigate } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { auth, db } from "../Component/firebase";
import Background from "../Assets/bg_img.jpg";
import "chartjs-adapter-date-fns"; // Import the date adapter
import Chart from "chart.js/auto";
import moment from "moment";

function Home() {
  const navigate = useNavigate();

  let user = auth.currentUser;

  const [availableBusiness, setAvailableDrivers] = useState([]);
  const [username, setUsername] = useState("");
  const [users, setUsers] = useState();
  const [business, setDrivers] = useState();
  const [sessions, setSessions] = useState();

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  function getUser() {
    const itemsRef1 = ref(db, "UserAccounts/" + user.uid);

    onValue(itemsRef1, (snapshot) => {
      const dataVal = snapshot.val();

      setUsername(dataVal.Username);
    });
  }

  useEffect(() => {
    getUser();
  }, []);

  async function getDetails(id) {}

  return (
    <div
      style={{
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: 30,
      }}
      className="mainDash"
    >
      <div
        style={{
          fontWeight: "bolder",
          fontSize: 16,
          textTransform: "uppercase",
          marginLeft: 20,
          backgroundColor: "white",
          borderRadius: 20,
          padding: 15,
        }}
      >
        WELCOME {username}
      </div>

      <div className="section1">
        <div className="card" onClick={() => navigate("/Accounts")}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div style={{ fontSize: 80, fontWeight: "800" }}>{users}</div>
            <span style={{ fontSize: 30 }}>USER</span>
          </div>

          <div
            style={{
              height: 100,
              borderWidth: 1,
              borderStyle: "solid",
            }}
          />
          <span>All available business signed up on the system.</span>
        </div>

        <div className="card" onClick={() => navigate("/Businesses")}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div style={{ fontSize: 80, fontWeight: "800" }}>{business}</div>
            <span style={{ fontSize: 30 }}>BUSINESS</span>
          </div>
          <div
            style={{
              height: 100,
              borderWidth: 1,
              borderStyle: "solid",
            }}
          />
          <span>All available users/clients signed up on the system.</span>
        </div>

        <div className="card" onClick={() => navigate("/sessions")}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div style={{ fontSize: 80, fontWeight: "800" }}>{sessions}</div>
            <span style={{ fontSize: 30 }}>BOOKINGS</span>
          </div>
          <div
            style={{
              height: 100,
              borderWidth: 1,
              borderStyle: "solid",
            }}
          />
          <span>All active bookings today.</span>
        </div>
      </div>

      <div className="section2">
        <div
          className="card2"
          style={{ backgroundColor: "white", color: "black" }}
        >
          <span style={{ fontWeight: "700", fontSize: 16 }}>
            BOOKINGS TODAY
          </span>

          {availableBusiness.length > 0 ? (
            availableBusiness.map((items, index) =>
              index < 2 ? (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                  className="itemName"
                  onClick={() => getDetails(items.id)}
                >
                  <div style={{ fontSize: 16, color: "blue" }}>
                    {items.name}
                  </div>
                  {/* <GetSessions id={items.id} />  */}
                </div>
              ) : (
                <div
                  style={{
                    marginTop: 20,
                    alignSelf: "center",
                    position: "absolute",
                    bottom: 10,
                    cursor: "pointer",
                    color: "blue",
                  }}
                  onClick={() => navigate("/sessions")}
                >
                  View All
                </div>
              )
            )
          ) : (
            <div
              style={{
                height: 300,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.5,
              }}
            >
              <span>NO DATA AVAILABLE</span>
            </div>
          )}
        </div>

        <div className="card2" />

        <div
          className="card3"
          style={{ backgroundColor: "white", color: "black" }}
        >
          <span style={{ fontWeight: "700", fontSize: 16 }}>SATISTICS</span>
          {chartRef !== null ? (
            <canvas
              ref={chartRef}
              width={100}
              height={100}
              style={{
                backgroundColor: "white",
                maxWidth: "100%",
                maxHeight: 300,
              }}
            ></canvas>
          ) : (
            <div
              style={{
                height: 300,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.5,
              }}
            >
              <span>NO DATA AVAILABLE</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
