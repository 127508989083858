import React, { useEffect, useState } from "react";
import Logo from "../Assets/book_logo3.png";
import { Link, useNavigate } from "react-router-dom";
import ReorderIcon from "@material-ui/icons/Reorder";
import "../Styles/Navbar.css";
import {
  ExitToAppOutlined,
  NotificationsOutlined,
  Notifications,
  PersonSharp,
} from "@material-ui/icons";

import { auth, db } from "../Component/firebase";
import { onValue, ref } from "firebase/database";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

function truncate(str) {
  return str.length > 10 ? str.substring(0, 25) + "..." : str;
}

function Navbar() {
  const navigation = useNavigate();

  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getUser(user);
      }
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const userId = user ? user.uid : null;

  //Mails
  const [messages, setMessages] = useState([]);
  const [masterDataSource, setMasterDataSource] = useState([]);
  const [numMails, setMails] = useState(0);
  const [search, setSearch] = useState("");

  const [notify, setNotify] = useState([]);
  const [notifyNumber, setNotifyNumber] = useState(0);
  const [isNotifications, setIsNotification] = useState(false);
  const [isProfile, setIsProfile] = useState(false);

  const [openLinks, setOpenLinks] = useState(false);

  const toggleNavbar = () => {
    setOpenLinks(!openLinks);
  };

  function logout() {
    signOut(auth).then(() => {
      window.localStorage.removeItem("isBookHubAdmin");
      navigation("/Login");
    });
  }

  const [userImage, setUserImage] = useState("");

  function getUser(user) {
    const itemsRef1 = ref(db, "Admin/Users/" + user.uid);

    onValue(itemsRef1, (snapshot) => {
      const dataVal = snapshot.val();
      setUserImage(dataVal.profile);
      setUserType(dataVal.AccountType);
    });
  }

  const ShowMails = ({ items }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "rgba(175, 175, 175, 0.2)",
          padding: 5,
          borderRadius: 5,
          margin: 5,
        }}
        onClick={() => {
          setIsNotification(false);
          handleUpdate(items);
        }}
      >
        <img
          src={
            userInfo
              ? userInfo.Profile
              : "https://media.istockphoto.com/id/1202490454/pt/vetorial/person-gray-photo-placeholder-man.jpg?s=612x612&w=0&k=20&c=HjvT-0ZeeZWzI78kVY7WSvl5C0Nex-qhWs66cQdPILg="
          }
          style={{ width: 30, height: 30, borderRadius: 20 }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 10,
          }}
        >
          <span className="notification-text2">{items.title}</span>
          <span
            className="notification-text3"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            {truncate(items.message)}
          </span>
        </div>
      </div>
    );
  };

  const [isRinging, setIsRinging] = useState(false);

  async function handleUpdate(items) {}

  return (
    <div className="navbar">
      <div className="leftSide" id={openLinks ? "open" : "close"}>
        <img src={Logo} alt={"LOGO"} style={{ width: 60, height: 60 }} />
        <div className="hiddenLinks">
          <Link to="/Home"> Dashboard </Link>
          <Link to="/Accounts">Accounts</Link>
          <Link to="/Businesses">Business</Link>
          <Link to="/Payments"> Payments </Link>
          <Link to="/Users">Users</Link>
        </div>
      </div>
      <div className="rightSide">
        <div
          onClick={() => {
            setIsProfile(false);
            setIsNotification(!isNotifications);
          }}
        >
          {messages.length > 0 ? (
            <div
              style={{
                position: "relative",
                animation: isRinging ? "ringing 0.5s linear infinite" : "none",
              }}
            >
              <Notifications
                style={{
                  fontSize: 40,
                  alignSelf: "center",
                  justifyContent: "center",
                  color: isRinging ? "red" : "black",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  padding: 2,
                  backgroundColor: "white",
                  fontSize: 10,
                  borderRadius: 5,
                  color: "black",
                }}
              >
                {numMails}
              </div>
            </div>
          ) : (
            <NotificationsOutlined
              style={{
                fontSize: 40,
                alignSelf: "center",
                justifyContent: "center",
                color: "white",
              }}
            />
          )}
        </div>
        <div
          onClick={() => {
            setIsNotification(false);
            setIsProfile(!isProfile);
          }}
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          {userImage != undefined ? (
            <img src={userImage} className="profImage" alt="User Image" />
          ) : (
            <PersonSharp
              style={{
                fontSize: 30,
                alignSelf: "center",
                justifyContent: "center",
                color: "white",
              }}
            />
          )}
        </div>
      </div>

      {isNotifications && messages.length > 0 ? (
        <div class="notification-container">
          <div class="triangle-container">
            <div class="box">
              <span class="notification-text">You have {numMails} message</span>
              <div
                style={{ height: 1, backgroundColor: "grey", marginBottom: 10 }}
              />

              {messages.length > 0 ? (
                messages.map((items, index) =>
                  index < 3 ? <ShowMails key={index} items={items} /> : null
                )
              ) : (
                <div>No Messages Available</div>
              )}

              <div
                style={{
                  alignSelf: "center",
                  position: "absolute",
                  bottom: 5,
                  marginTop: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsNotification(false);
                  navigation("settings", {
                    state: { data: "Notifications" },
                  });
                }}
              >
                <span class="notification-text2">show all messages</span>
              </div>
            </div>
            <div class="triangle"></div>
          </div>
        </div>
      ) : null}

      {isProfile ? (
        <div class="notification-container2">
          <div class="triangle-container2">
            <div class="box2">
              <div
                className="innerBox"
                style={{
                  backgroundColor: userType === "Staff" ? "white" : "teal",
                }}
              >
                {userImage != undefined ? (
                  <img
                    src={userImage}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: 150,
                      margin: 10,
                      objectFit: "cover",
                    }}
                    alt="User Image"
                  />
                ) : (
                  <img
                    src={"https://bit.ly/42v16s3"}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://bit.ly/42v16s3";
                    }}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: 150,
                      margin: 10,
                      objectFit: "cover",
                    }}
                  />
                )}

                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    color: userType === "Staff" ? "black" : "white",
                  }}
                >
                  {userInfo.Name}
                </div>

                <div
                  style={{
                    fontSize: 14,
                    color: userType === "Staff" ? "black" : "white",
                  }}
                >
                  {userType}
                </div>
              </div>

              <div
                style={{
                  borderTopColor: "teal",
                  borderTopWidth: 2,
                  borderTopStyle: "solid",
                  marginBottom: 10,
                }}
              />
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 10,
                }}
              >
                <button
                  style={{
                    minWidth: 50,
                    padding: 5,
                    backgroundColor: "black",
                    borderColor: "transparent",
                    color: "white",
                    borderRadius: 5,
                    cursor: "pointer",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    setIsNotification(false);
                    setIsProfile(false);
                    navigation("/settings");
                  }}
                >
                  Profile
                </button>
                <button
                  onClick={() => logout()}
                  style={{
                    minWidth: 50,
                    padding: 5,
                    backgroundColor: "red",
                    borderColor: "transparent",
                    color: "white",
                    borderRadius: 5,
                    cursor: "pointer",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Logout
                </button>
              </div>
              <div
                class="triangle"
                style={{
                  borderBottom:
                    userType === "Staff"
                      ? "10px solid #fff"
                      : "10px solid teal",
                }}
              ></div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Navbar;
