import React, { useEffect } from "react";
import "../Styles/SplashScreen.css";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loadingLottie from "../Assets/loading_Lottie.json";
import { auth } from "../Component/firebase";

function SplashScren() {
  const navigation = useNavigate();

  let logInUser = window.localStorage.getItem("isBookHubAdmin");

  useEffect(() => {
    setTimeout(() => {
      const unsub = auth.onAuthStateChanged((authObj) => {
        unsub();
        if (authObj) {
          navigation("/Home");
        } else {
          navigation("/Login");
        }
      });
    }, 5000);
  }, []);

  return (
    <div className="splash-container">
      <Lottie
        animationData={loadingLottie}
        loop={true}
        style={{ width: 200, height: 200 }}
      />
      <h2>BOOK HUB</h2>
      <span style={{ marginTop: 10 }}>
        &copy; {new Date().getFullYear()} amplelarktech.com
      </span>
    </div>
  );
}

export default SplashScren;
