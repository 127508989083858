import React, { useEffect, useState } from "react";
import "../Styles/setting.css";
import Security from "../Component/Security";
import { useLocation, useNavigate } from "react-router-dom";
import Notifications from "../Component/Notifications";
import UserAccounts from "../Component/UserAccounts";

function Settings() {
  const location = useLocation();
  const navigate = useNavigate();

  const data = location.state?.data || "No data received";
  const openNotif = location.state?.openNotif || null;

  const [pPage, setPPage] = useState(true);
  const [sPage, setSPage] = useState(false);
  const [nPage, setNPage] = useState(false);
  const [uPage, setUPage] = useState(false);
  const [rPage, setRPage] = useState(false);

  useEffect(() => {
    if (data === "Notifications" || openNotif !== null) {
      setPPage(false);
      setSPage(false);
      setNPage(true);
      setUPage(false);
      setRPage(false);
    }
  }, []);

  return (
    <div className="setting-tab">
      <div className="section1">
        <div
          className="head"
          style={{
            backgroundColor: pPage ? "teal" : "transparent",
            color: pPage ? "white" : "black",
          }}
          onClick={() => {
            setPPage(true);
            setSPage(false);
            setNPage(false);
            setUPage(false);
            setRPage(false);
          }}
        >
          Profile
        </div>
        <div
          className="head"
          style={{
            backgroundColor: sPage ? "teal" : "transparent",
            color: sPage ? "white" : "black",
          }}
          onClick={() => {
            setPPage(false);
            setSPage(true);
            setNPage(false);
            setUPage(false);
            setRPage(false);
          }}
        >
          Security
        </div>
        <div
          className="head"
          style={{
            backgroundColor: nPage ? "teal" : "transparent",
            color: nPage ? "white" : "black",
          }}
          onClick={() => {
            setPPage(false);
            setSPage(false);
            setNPage(true);
            setUPage(false);
            setRPage(false);
          }}
        >
          Notifications
        </div>
        <div
          className="head"
          style={{
            backgroundColor: uPage ? "teal" : "transparent",
            color: uPage ? "white" : "black",
          }}
          onClick={() => {
            setPPage(false);
            setSPage(false);
            setNPage(false);
            setUPage(true);
            setRPage(false);
          }}
        >
          User Accounts
        </div>
        <div
          className="head"
          style={{
            backgroundColor: rPage ? "teal" : "transparent",
            color: rPage ? "white" : "black",
          }}
          onClick={() => {
            setPPage(false);
            setSPage(false);
            setNPage(false);
            setUPage(false);
            setRPage(true);
          }}
        >
          Reports
        </div>
      </div>

      <div className="section2">
        {sPage ? (
          <Security />
        ) : nPage ? (
          <Notifications data={openNotif} />
        ) : uPage ? (
          <UserAccounts />
        ) : null}
      </div>
    </div>
  );
}

export default Settings;
