import React, { useEffect, useState } from "react";
import "../Styles/Users.css";
import Background from "../Assets/bg_img.jpg";
import { get, onValue, ref } from "firebase/database";
import { auth, db } from "../Component/firebase";
import { CancelRounded } from "@material-ui/icons";

const Bookings = () => {
  let user = auth.currentUser;

  const [data, setData] = useState("");

  const itemsRef1 = ref(db, "Admin/Users/" + user.uid);

  const CheckAccount = () => {
    get(itemsRef1).then((snapshot) => {
      const dataVal = snapshot.val();

      if (
        dataVal.AccountType == "Administrator" ||
        dataVal.AccountType == "Accountant"
      ) {
        setData("Yes");
      } else {
        setData("No");
      }
    });
  };

  useEffect(() => {
    CheckAccount();
  }, []);

  if (data == "Yes") {
    return (
      <div
        className="container"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: 30,
        }}
      >
        <div className="body-section-users">
          <div className="header-section-users">
            <h3>Bookings</h3>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="container"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: 30,
        }}
      >
        <div className="body-section-users">
          <div className="show-No-Data">
            <CancelRounded
              style={{
                fontSize: 80,
                alignSelf: "center",
                justifyContent: "center",
                color: "red",
              }}
            />
            <span
              style={{
                fontSize: 20,
                textAlign: "center",
              }}
            >
              No Sufficient Privileges to Access
            </span>
          </div>
        </div>
      </div>
    );
  }
};

export default Bookings;
